<template>
  <EditDataRepairsLayout>
    <div class="wrapper col-md-6">
      <table class="col-md-12">
        <tr>
          <td>
            <select
              class="form-control form-control-sm shadow-none"
              v-model="typeOfRepair"
              @change="changeTypeOfRepair"
            >
              <option value="repair">ремонт</option>
              <option value="noRepair">отказ от ремонта</option>
              <option value="diagnostiks">диагностика</option>
            </select>
          </td>
        </tr>
        <tr v-if="visibleOptionNoRepair">
          <td>
            <select
              class="form-control form-control-sm shadow-none"
              v-model="noRepairType"
              @click="changeNoRepairType"
            >
              <option value="1">отказ от ремонта в течении 15 дней</option>
              <option value="2"
                >отказ от ремонта в течении года только Леруа, Касторама и ОБИ
              </option>
              <option value="3">отсутствие запчастей для ремонта</option>
              <option value="4">другое</option>
            </select>
          </td>
        </tr>
        <tr>
          <td v-if="visibleWotkCarried">
            <textarea
              rows="3"
              class="form-control form-control-sm shadow-none"
              v-model="workCarried"
              placeholder="workCarried"
            ></textarea>
          </td>
        </tr>
        <tr v-if="visibleNoRepairOther">
          <td>
            <textarea
              rows="3"
              class="form-control form-control-sm shadow-none"
              v-model="noRepairTypeOther"
              placeholder="укажите причину отказа от ремонта"
            ></textarea>
          </td>
        </tr>
      </table>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
export default {
  name: "EditRepairOptions",
  components: {
    EditDataRepairsLayout
  },
  props: ["data"],
  data: () => ({
    typeOfRepair: null,
    visibleOptionNoRepair: false,
    workCarried: null,
    visibleWotkCarried: false,
    visibleNoRepairOther: false,
    noRepairTypeOther: null,
    noRepairType: null
  }),
  methods: {
    changeTypeOfRepair() {
      console.log(this.typeOfRepair);
      if (this.typeOfRepair === "noRepair") {
        this.visibleOptionNoRepair = true;
        this.visibleWotkCarried = false;
        this.noRepairType = "1";
      } else {
        this.visibleOptionNoRepair = false;
        this.visibleWotkCarried = true;
        this.visibleNoRepairOther = false;
      }
    },
    changeNoRepairType() {
      if (this.noRepairType === "4") {
        this.visibleNoRepairOther = true;
      } else {
        this.visibleNoRepairOther = false;
      }
    }
  },
  mounted() {
    this.workCarried = this.data.workCarried;
    this.typeOfRepair = this.data.typeOfRepair;
    this.noRepairTypeOther = this.data.noRepairTypeOther;
    this.noRepairType = this.data.noRepairType;
    if (this.typeOfRepair !== "noRepair") {
      this.visibleWotkCarried = true;
    } else {
      this.visibleWotkCarried = false;
      this.visibleOptionNoRepair = true;
    }
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
