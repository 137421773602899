<template>
  <div class="edit">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "EditDataRepairsLayout"
};
</script>
<style lang="scss" scoped>
.edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #ffffff, $alpha: 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
