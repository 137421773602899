<template>
  <div class="foto-wrapper">
    <div class="form-row">
      <div class="form-group col-md-6">
        <div v-if="data.dat">
          <div class="form-row">
            <table class="table table-sm">
              <tbody>
                <tr>
                  <td>наименование изделия</td>
                  <td>{{ data.dat.name_instr }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="edit('VisibleEditInstr')"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>буквы серийного номера</td>
                  <td>{{ data.dat.serialNumberGroup }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeriaNumber"></i>
                  </td>
                </tr>
                <tr>
                  <td>серийный номер</td>
                  <td>{{ data.dat.serialNumber }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeriaNumber"></i>
                  </td>
                </tr>
                <tr>
                  <td>серийный номер отсутствует</td>
                  <td>{{ data.dat.notSerialNumber === "1" ? "да" : "нет" }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeriaNumber"></i>
                  </td>
                </tr>
                <tr>
                  <td>предторг</td>
                  <td>{{ data.dat.predtorg === "1" ? "да" : "нет" }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDataSale"></i>
                  </td>
                </tr>
                <tr>
                  <td>дата продажи</td>
                  <td>{{ data.dat.dateSale }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDataSale"></i>
                  </td>
                </tr>
                <tr>
                  <td>дата ремонта</td>
                  <td>{{ data.dat.dateRepair }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDataSale"></i>
                  </td>
                </tr>
                <tr>
                  <td>продавец</td>
                  <td>{{ data.dat.seller }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editSeller"></i>
                  </td>
                </tr>
                <tr>
                  <td>дефект</td>
                  <td>{{ data.dat.defect }}</td>
                  <td>
                    <i class="bi bi-pencil-square" @click="editDefect"></i>
                  </td>
                </tr>
                <tr>
                  <td>требование владельца</td>
                  <td>{{ typeOfRepair(data.dat.typeOfRepair) }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="editRepairOptions"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>проведенные работы</td>
                  <td>{{ data.dat.workCarried }}</td>
                  <td>
                    <i
                      class="bi bi-pencil-square"
                      @click="editRepairOptions"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>номер квитанции СЦ</td>
                  <td>{{ data.dat.receiptNumber }}</td>
                  <td><i class="bi bi-pencil-square"></i></td>
                </tr>
                <tr>
                  <td>комментарий</td>
                  <td>{{ data.dat.comment }}</td>
                  <td><i class="bi bi-pencil-square"></i></td>
                </tr>
                <tr v-if="data.zap">
                  <td colspan="3">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th colspan="3" class="text-center">запчасти</th>
                          <th><i class="bi bi-pencil-square"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in data.zap" :key="idx">
                          <td>{{ item.name }}</td>
                          <td>{{ item.art }}</td>
                          <td>{{ item.cod }}</td>
                          <td>{{ item.pcs }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else />
      </div>
      <div class="form-group col-md-6" id="one-repair-right">
        <PhotoWrapper
          :dataPhoto="data.labelFoto"
          :data="data.dat"
          type="labelFoto"
          title="фото шильдика с серийным номером"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.ticketFoto"
          :data="data.dat"
          type="ticketFoto"
          title="фото гарантийного талона в паспорте изделия"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.viewFoto"
          :data="data.dat"
          type="viewFoto"
          title="фото внешнего вида изделия"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.defectDetailFoto"
          :data="data.dat"
          type="defectDetailFoto"
          title="фото дефекта, дефектных деталей"
          @update="get_data"
          @addFile="addFile"
        />
        <PhotoWrapper
          :dataPhoto="data.dataAdditionalFoto"
          :data="data.dat"
          type="dataAdditionalFoto"
          title="дополнительные материалы"
          @update="get_data"
          @addFile="addFile"
        />
      </div>
    </div>
    <EditInstr
      v-if="VisibleEditInstr"
      :data="data.dat"
      @cancelEditInstr="cancelEditInstr"
    />
    <div v-else />
    <AddFileForRepair
      v-if="title"
      :typeFileFoto="typeFileFoto"
      :title="title"
      :remont_id="data.dat.remont_id"
      :type="type"
      @addFileClose="addFileClose"
    />
    <div v-else />
    <EditSerialNumber
      v-if="visibleEditSerialNumber"
      :data="data.dat"
      @close="cancelEditSerialNumber"
    />
    <div v-else />
    <EditDataSale
      v-if="visibleEditDataSale"
      :data="data.dat"
      @cancel="cancelEditDataSale"
    />
    <div v-else />
    <EditSeller
      v-if="visibleEditSeller"
      :data="data.dat"
      @cancel="cancelEditSeller"
    />
    <div v-else />
    <EditDefect
      v-if="visibleEditDefect"
      :data="data.dat"
      @cancel="cancelEditDefect"
    />
    <div v-else />
    <EditRepairOptions v-if="visibleEditRepairOptions" :data="data.dat" />
    <div v-else />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import PhotoWrapper from "@/components/PhotoWrapper.vue";
import EditInstr from "@/views/repairs/editRepair/EditInstr.vue";
import AddFileForRepair from "@/views/repairs/editRepair/AddFileForRepair.vue";
import EditSerialNumber from "@/views/repairs/editRepair/EditSerialNumber.vue";
import EditDataSale from "@/views/repairs/editRepair/EditDataSale.vue";
import EditSeller from "@/views/repairs/editRepair/EditSeller.vue";
import EditDefect from "@/views/repairs/editRepair/EditDefect.vue";
import EditRepairOptions from "@/views/repairs/editRepair/EditRepairOptions.vue";
export default {
  name: "OneRepair",
  components: {
    PhotoWrapper,
    EditInstr,
    AddFileForRepair,
    EditSerialNumber,
    EditDataSale,
    EditSeller,
    EditDefect,
    EditRepairOptions
  },
  data: () => ({
    data: {
      dat: {},
      labelFoto: {},
      ticketFoto: {},
      viewFoto: {},
      defectDetailFoto: {},
      dataAdditionalFoto: {},
      zap: {}
    },
    VisibleEditInstr: false,
    title: null,
    typeFileFoto: null,
    type: null,
    visibleEditSerialNumber: false,
    visibleEditDataSale: false,
    visibleEditSeller: false,
    visibleEditDefect: false,
    visibleEditRepairOptions: false
  }),
  methods: {
    get_data() {
      HTTPGET({
        url: "repairs/get_one_repair.php",
        params: {
          id: this.$route.params.id
        }
      }).then(resp => (this.data = resp.data));
    },
    typeOfRepair(type) {
      if (type === "repair") {
        return "ремонт";
      }
      if (type === "diagnostiks") {
        return "диагностика";
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "1") {
          return "отказ от ремонта в течении 15 дней";
        }
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "2") {
          return "отказ от ремонта в течении года только Леруа, Касторама и ОБИ";
        }
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "3") {
          return "отсутствие запчастей для ремонта";
        }
      }
      if (type === "noRepair") {
        if (this.data.dat.noRepairType === "4") {
          return this.data.dat.noRepairTypeOther;
        }
      }
    },
    edit(field) {
      this[field] = true;
    },
    addFile(data) {
      this.type = data.type;
      this.title = data.title;
      if (data.type !== "dataAdditionalFoto") {
        this.typeFileFoto = /\.(jpe?g|png|gif)$/i;
      }
      if (data.type === "dataAdditionalFoto") {
        this.typeFileFoto = /\.(jpe?g|png|gif|avi|mp4|mov|mp3|xls|xlsx|pdf|docx|doc)$/i;
      }
    },
    addFileClose() {
      this.title = null;
      this.typeFileFoto = null;
      this.type = null;
      this.get_data();
    },
    cancelEditInstr() {
      this.VisibleEditInstr = false;
      this.get_data();
    },
    editSeriaNumber() {
      this.visibleEditSerialNumber = true;
    },
    cancelEditSerialNumber() {
      this.visibleEditSerialNumber = false;
      this.get_data();
    },
    editDataSale() {
      this.visibleEditDataSale = true;
    },
    cancelEditDataSale() {
      this.get_data();
      this.visibleEditDataSale = false;
    },
    editSeller() {
      this.visibleEditSeller = true;
    },
    cancelEditSeller() {
      this.get_data();
      this.visibleEditSeller = false;
    },
    editDefect() {
      this.visibleEditDefect = true;
    },
    cancelEditDefect() {
      this.get_data();
      this.visibleEditDefect = false;
    },
    editRepairOptions() {
      this.visibleEditRepairOptions = true;
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 95%;
}
.foto-row {
  justify-content: center;
}
.foto-wrapper {
  padding: 10px;
  width: 100%;
  overflow: auto;
}
table {
  font-size: 0.9em;
}
#one-repair-right {
  height: 80vh;
  overflow: auto;
}
i {
  cursor: pointer;
}
</style>
