<template>
  <EditDataRepairsLayout>
    <div class="wrapper">
      <table>
        <tbody>
          <tr>
            <td>буквы серийного номера</td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="serialNumberGroup"
                @input="input"
                :class="invalidClassGroup"
              />
            </td>
          </tr>
          <tr>
            <td>цифры серийного номера</td>
            <td>
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                v-model="serialNumber"
                @input="input"
                :class="invalidClassNumber"
              />
            </td>
          </tr>

          <tr>
            <td>серийный номер отсутствует</td>
            <td class="text-left">
              <input
                type="checkbox"
                class="form-control form-control-sm input-form shadow-none checkbox"
                v-model="checkbox"
                @input="log"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <button
          id="save"
          class="btn btn-sm btn-outline-success shadow-none"
          @click="save"
          :disabled="buttonSubmitDisable"
        >
          сохранить
        </button>
        <button
          class="btn btn-sm btn-outline-danger shadow-none"
          @click="cancel"
        >
          отмена
        </button>
      </div>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
import { HTTPGET } from "@/api";
export default {
  name: "EditSerialNumber",
  props: ["data"],
  components: {
    EditDataRepairsLayout
  },
  data: () => ({
    checkbox: null,
    serialNumberGroup: null,
    serialNumber: null,
    invalidClassGroup: "",
    invalidClassNumber: "",
    error: true,
    buttonSubmitDisable: true
  }),
  mounted() {
    if (this.data.notSerialNumber === "0") {
      this.checkbox = false;
    } else {
      this.checkbox = true;
    }
    this.serialNumberGroup = this.data.serialNumberGroup;
    this.serialNumber = this.data.serialNumber;
  },
  methods: {
    log() {
      if (this.checkbox) {
        this.serialNumberGroup = this.data.serialNumberGroup;
        this.serialNumber = this.data.serialNumber;
        if (this.serialNumberGroup.trim()) {
          this.invalidClassGroup = "";
          this.error = false;
        }
        if (!this.serialNumberGroup.trim()) {
          this.invalidClassGroup = "is-invalid";
          this.error = true;
        }
        if (this.serialNumber.trim()) {
          this.invalidClassNumber = "";
          this.error = false;
        }
        if (!this.serialNumber.trim()) {
          this.invalidClassNumber = "is-invalid";
          this.error = true;
        }
      }
      if (!this.checkbox) {
        this.invalidClassGroup = "";
        this.invalidClassNumber = "";
        this.serialNumberGroup = null;
        this.serialNumber = null;
        this.error = false;
      }
      if (
        this.serialNumberGroup.trim() === this.data.serialNumberGroup &&
        this.serialNumber.trim() === this.data.serialNumber
      ) {
        this.error = true;
      }
      this.buttonSubmitDisable = this.error;
    },
    input() {
      if (this.checkbox) {
        this.serialNumberGroup = "";
        this.serialNumber = "";
        this.error = false;
      } else {
        if (this.serialNumberGroup.trim()) {
          this.invalidClassGroup = "";
          this.error = false;
        }
        if (this.serialNumber.trim()) {
          this.invalidClassNumber = "";
          this.error = false;
        }
        if (!this.serialNumberGroup.trim()) {
          this.invalidClassGroup = "is-invalid";
          this.error = true;
        }
        if (!this.serialNumber.trim()) {
          this.invalidClassNumber = "is-invalid";
          this.error = true;
        }
      }
      this.serialNumberGroup.trim() === ""
        ? (this.serialNumberGroup = null)
        : null;
      this.serialNumber.trim() === "" ? (this.serialNumber = null) : null;
      if (
        this.serialNumberGroup.trim() === this.data.serialNumberGroup &&
        this.serialNumber.trim() === this.data.serialNumber
      ) {
        this.error = true;
      }
      this.buttonSubmitDisable = this.error;
    },
    cancel() {
      this.$emit("close");
    },
    save() {
      if (!this.error) {
        if (
          this.serialNumberGroup.trim() !== this.data.serialNumberGroup ||
          this.serialNumber.trim() !== this.data.serialNumber
        ) {
          this.submit();
        }
      }
    },
    submit() {
      HTTPGET({
        url: "repairs/edit_repair_serial_number.php",
        params: {
          serialNumberGroup: this.serialNumberGroup,
          serialNumber: this.serialNumber,
          notSerialNumber: this.checkbox,
          remont_id: this.data.remont_id
        }
      }).then(() => this.$emit("close"));
    }
  }
};
</script>
<style lang="scss" scoped>
.checkbox {
  height: 1.2em;
  width: 1.2em;
}
td {
  padding: 3px;
}
.wrapper {
  padding: 15px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
