<template>
  <EditDataRepairsLayout>
    <div class="card col-md-6">
      <div class="card-body text-center">
        <small class="card-title">выберете изделие</small>
        <p class="card-text">
          <Selected
            :title="data.name_instr"
            :setChangeInstr="true"
            @updateCodInstr="setCodInstr"
            :open="true"
          />.
        </p>
        <button
          class="btn btn-sm btn-outline-success shadow-none"
          @click="save"
        >
          соохранить
        </button>
        <button
          class="btn btn-sm btn-outline-danger shadow-none"
          @click="cancel"
        >
          отмена
        </button>
      </div>
    </div>
  </EditDataRepairsLayout>
</template>
<script>
import { HTTPGET } from "@/api";
import Selected from "@/components/Selected.vue";
import EditDataRepairsLayout from "@/layouts/EditDataRepairsLayout.vue";
export default {
  name: "EditInstr",
  props: ["data"],
  components: {
    Selected,
    EditDataRepairsLayout
  },
  data: () => ({
    cod: null
  }),
  methods: {
    setCodInstr(cod) {
      this.cod = cod;
    },
    cancel() {
      this.$emit("cancelEditInstr");
    },
    save() {
      if (this.cod) {
        HTTPGET({
          url: "repairs/edit_instr_repair.php",
          params: {
            cod: this.cod,
            id: this.data.remont_id
          }
        }).then(() => this.$emit("cancelEditInstr"));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
  margin-left: 10px;
}
</style>
