<template>
  <div>
    <div class="item-photo-wrapper">
      <div class="form-row">
        <div class="form-group col-md-12 text-center">
          <h6>{{ title }}</h6>
          <div>
            <button
              class="btn btn-sm btn-outline-success shadow-none"
              @click="
                addFile({ remont_id: data.remont_id, type: type, title: title })
              "
            >
              добавить файл
            </button>
          </div>
        </div>
      </div>
      <div class="form-row foto-row">
        <div
          v-for="(item, idx) in dataPhoto"
          :key="idx"
          class="form-group col-md-4"
        >
          <p class="text-center">
            <a :href="item.path0 + item.path" target="blank">{{ item.name }}</a>
          </p>
          <div>
            <img
              class="min-photo"
              :src="item.path"
              alt=""
              @click="viewPhoto(item.path)"
            />
          </div>
          <div class="text-center">
            <button
              class="btn btn-sm btn-outline-danger shadow-none delete"
              @click="del(data.remont_id, data.id_user, item.name)"
            >
              удалить
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="big-photo" v-if="visibleBigFoto" @click="hideBigPhoto">
      <img :src="bigPhotoUrl" alt="" @click="hideBigPhoto" />
    </div>
    <div v-else />
    <ModalMessage
      v-if="visibleMessage"
      @hideMessage="hideMessage"
      message="в каждой категории кроме категории 
      'дополнительные материалы' 
      должно быть минимум одно фото. 
      Сначала добавьте нужное фото 
      потом удалите ненужное"
    />
    <div v-else />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import ModalMessage from "@/components/ModalMessage.vue";
export default {
  name: "PhotoWrapper",
  props: ["data", "dataPhoto", "title", "type"],
  components: {
    ModalMessage
  },
  data: () => ({
    user: null,
    visibleBigFoto: false,
    bigPhotoUrl: null,
    visibleMessage: false
  }),
  methods: {
    del(id, user, name) {
      if (this.type !== "dataAdditionalFoto") {
        if (this.dataPhoto.length < 2) {
          this.visibleMessage = true;
          return;
        }
      }
      if (confirm(`точно удалить ${name}`)) {
        HTTPGET({
          url: "repairs/delete_photo.php",
          params: {
            id,
            user,
            name,
            type: this.type
          }
        }).then(() => this.$emit("update"));
      }
    },
    addFile(data) {
      this.$emit("addFile", data);
    },
    hideMessage() {
      this.visibleMessage = false;
    },
    viewPhoto(url) {
      this.visibleBigFoto = true;
      this.bigPhotoUrl = url;
    },
    hideBigPhoto() {
      this.visibleBigFoto = false;
      this.bigPhotoUrl = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.min-photo {
  width: 95%;
}
.foto-row {
  justify-content: center;
}
.item-photo-wrapper {
  border-bottom: 1px solid rgb(192, 192, 192);
}
p {
  overflow: hidden;
}
.btn {
  font-size: 0.7em;
}
.big-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  background: black;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.big-photo > img {
  height: 95%;
}
.big-photo,
img {
  cursor: pointer;
}
.delete {
  margin-top: 10px;
}
</style>
